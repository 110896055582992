@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: "Nunito", sans-serif;
}

html {
  font-size: 16px;
}

p,
label {
  font-weight: 200;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
}
