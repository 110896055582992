.second-main {
  .card {
    .description-title {
      font-size: 1.25rem;

      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }
  }
}

.demo {
  .drag-area {
    @media (hover: none) {
      outline: 2px dashed #92b0b3;
    }
  }

  .faq {
    visibility: hidden;
    pointer-events: none;
  }

  .AssistantMio {
    bottom: 65px;

    @include media-breakpoint-up(md) {
      bottom: auto;
      transform: translateX(-90px);
    }

    .content-holder {
      padding-left: 9px;

      @include media-breakpoint-up(md) {
        padding-left: 24px;
      }
    }
  }

  .footer {
    .content {
      .countdown-content {
        border-radius: 30px 30px 0 0;
        min-width: 170px;
        margin: 0 25px -2px;
        height: max-content;
        padding: 5px 0;

        @include media-breakpoint-up(md) {
          border-radius: 60px 60px 0 0;
          min-width: 220px;
          margin: 0;
          height: 50px;
          padding: 0;
        }
      }

      .users-content {
        border-radius: 30px 30px 0 0;
        max-width: 100vw;

        @include media-breakpoint-up(md) {
          border-radius: 50px 50px 0 0;
        }
        .users-holder {
          grid-template-columns: 1fr;
          margin: 0;
          column-gap: 0;

          @include media-breakpoint-up(md) {
            width: 33.3% !important;
          }
        }
      }

      .game-end-button {
        .button-icon {
          width: 24px;

          @include media-breakpoint-up(md) {
            width: 16px;
          }
        }
      }
    }
  }
}
