.AssistantMio {
  height: var(--mio-height);
  z-index: 99999;
  bottom: 45px;
  position: absolute;
  left: 0;

  @include media-breakpoint-up(md) {
    margin-left: 8px;
    bottom: auto;
    position: relative;
  }

  .content-holder {
    .mio-name,
    .mio-close,
    .mio-next-button,
    .mio-content {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      visibility: hidden;
      transition: opacity 200ms ease 0ms, z-index 0ms ease 210ms,
        pointer-events 200ms ease 0ms, visibility 0ms ease 210ms;
    }
  }

  .content-holder {
    width: max-content;
    max-width: 94vw;
    padding: 24px 24px 24px 12px;
    border-radius: 20px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
      max-width: 590px;
    }

    .mio-name {
      top: 0;
      left: 17px;
      transform: translateY(-50%);
      background: #e85ac0;
      border: 1px solid #6554c0;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      color: #090428;
      padding: 4px 6px;
      border-radius: 8px;
      z-index: 9;
    }

    .mio-close {
      top: -10px;
      right: -10px;
      width: 34px;
      height: 34px;
      background-color: #090428;
      border: 1px solid #252040;
      z-index: 9;
    }

    .mio-next-button {
      bottom: 3px;
      right: 3px;
      font-size: 14px;
      background: #e85ac0;
      color: #fff;
      border-radius: 6px;
      padding: 2px 6px;
    }

    .mio-icon {
      width: 42px;
      height: 42px;

      img {
        object-fit: cover;
      }
    }

    .mio-contents {
      margin-left: 12px;
      padding: 10px 0;
      max-width: 1px;
      transition: max-width 0ms ease 210ms;

      .mio-content {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        width: max-content;
        max-width: 70vw;

        @include media-breakpoint-up(md) {
          max-width: 500px;
        }
      }
    }
  }

  &[data-is-in="true"] {
    .content-holder {
      background-color: #090428;
      box-shadow: 0 0 7px 14px rgba($color: #000000, $alpha: 0.1);

      .mio-name,
      .mio-close,
      .mio-next-button {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
        visibility: visible;
        transition: opacity 200ms ease 10ms, z-index 0ms ease 0ms,
          pointer-events 200ms ease 10ms, visibility 0ms ease 0ms;
      }

      .mio-content {
        &[data-is-in="true"] {
          opacity: 1;
          z-index: 2;
          pointer-events: all;
          visibility: visible;
          transition: opacity 200ms ease 10ms, z-index 0ms ease 0ms,
            pointer-events 200ms ease 10ms, visibility 0ms ease 0ms;
        }
      }

      .mio-contents {
        max-width: 100vw;
        transition-delay: 0ms;
      }

      .mio-close,
      .mio-next-button,
      .mio-name {
        z-index: 9;
      }
    }
  }
}
