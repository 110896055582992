.input-holder {
  .input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
    isolation: isolate;
    background: #171234;
    border: 1px solid #322e4c;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    &:focus {
      outline: none;
      border: 1px solid #6554c0;
    }
  }
  .input-area {
    .input-char-limit {
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      color: rgba($color: #ffffff, $alpha: 0.4);
      font-size: 12px;
    }
  }
}
