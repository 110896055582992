.room {
  background: #171234;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 3px transparent;
  border-radius: 50%;
  cursor: pointer;

  .content {
    top: 0;
    left: 0;
    transition: opacity 200ms;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #6554c0, #e85ac0);
    transition: all 200ms;
  }

  & > [style^="--aspect-ratio"] {
    &::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }

  &-user-count {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #6554c0;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
  }

  &-status {
    font-size: 18px;

    &-ready {
      color: #267f61;
    }
    &-in-game {
      color: #990000;
    }
  }

  &.selected {
    .content {
      opacity: 1 !important;
    }

    &:before {
      background: #fff !important;
      opacity: 1 !important;
    }
  }
}

.add-room {
  background: #171234;
  border: 4px dashed #5c5870;
  border-radius: 50%;
  cursor: pointer;

  &-icon {
    border: solid 2px #6554c0;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    img {
      object-fit: cover;
      margin-top: -5px;
    }
  }

  &-text {
    font-size: 22px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
}

.rooms {
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  padding-top: 30px;
  place-items: start;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 80%;
    background-color: rgba(100, 84, 192, 0.336);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6554c0;
    border-radius: 10px;
    height: 10px !important;
  }

  &[data-has-joined] {
    .room {
      pointer-events: none;

      .content {
        opacity: 0.5;
      }

      &::before {
        opacity: 0.5;
      }
    }
  }
}

.musing-checkbox {
  font-size: 24px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(50, 46, 76, 1);
  padding: 14px 24px;
  background: rgba(23, 18, 52, 0.5);
  border: 2px solid #322e4c;
  border-radius: 20px;
  cursor: pointer;
  transition: all 200ms;

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #6554c0;
    background: #171234;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms;
  }

  &[data-is-selected="true"] {
    border-color: #8777d9;
    .circle {
      border-width: 8px;
      border-color: #8777d9;
    }
  }
}
