.circle-button {
  width: 75px;
  height: 75px;
  background: rgba(9, 4, 40, 0.7);
  box-shadow: 0px 0px 3px $dark-blue, inset 0px 0px 1px 0.5px $pink;
  backdrop-filter: blur(10px);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: $purple;

  @include media-breakpoint-up(md) {
    width: 90px;
    height: 90px;
  }
}
