.virtuoso {
  background: url("../../assets/img/gamer-bg.png");
  background-size: cover;
  background-position: center 10px;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: calc(var(--footer-height) * -1);

  @include media-breakpoint-up(md) {
    background-size: calc(100vw + 30px);
  }

  &.demo-fix {
    .card {
      height: auto;
      padding-top: 0;

      @include media-breakpoint-up(md) {
        height: 70vh;
      }
    }
  }

  .notes-holder {
    padding: 25px 15px;

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    .note-holder {
      width: 92px;
      height: calc(25vh - 20px);
      opacity: 1;
      transition: opacity 200ms;
      max-height: 200px;

      @include media-breakpoint-up(md) {
        max-height: 100%;
      }

      @media screen {
        @media (min-height: 789px) {
          height: calc(30vh - 32px);
        }

        @media (min-height: 821px) {
          height: calc(35vh - 32px);
        }
      }

      &[data-is-added="true"] {
        opacity: 0.5;
      }

      .note {
        width: 48px;
        max-width: 70%;
        min-width: 32px;
      }
    }
  }

  .play-button {
    width: 40px;
    height: 40px;
  }

  .play-button-area,
  .play-song-button {
    .play-button {
      width: auto;
      height: auto;
      border-radius: 8px;
      padding: 6px 10px;
      color: #fff;
    }
  }

  .progress {
    width: 100%;
    height: 5px;
    background: #252040;
    overflow: visible;

    input[type="range"] {
      top: 0;
      left: 0;
      z-index: 99;
      opacity: 0;
    }

    &-bar {
      height: 5px;
      background: #00b8d9;
      width: 0;
      transition: width 0.1s linear;
    }

    &-text {
      font-size: 12px;
    }

    &-ball {
      width: 10px;
      height: 10px;
      background: #00b8d9;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: var(--left);
      transform: translateY(-50%);
      transition: left 0.1s linear;
    }

    @for $i from 0 through 100 {
      &-bar[data-progress="#{$i}"] {
        width: #{$i + "%"};
      }
    }
  }

  .pitch-area {
    height: 25vh;
    max-height: 90px;
    width: 85%;
    z-index: 2;

    @media screen {
      @media (min-height: 789px) {
        max-height: 110px;
      }
    }

    .pitch {
      position: relative;
      height: 100%;
      width: 100%;
      background: rgba(64, 60, 88, 0.6);
      border: 1px solid #aeadb8;
      overflow: hidden;

      &:nth-child(1) {
        border-radius: 12px 0 0 12px;
      }

      &:last-child {
        border-radius: 0 12px 12px 0;
      }

      &-green {
        border: 1px solid #2ca800;
        background: rgba(44, 168, 0, 0.05);
      }

      &-blue {
        border: 1px solid #00b8d9;
        background: rgba(0, 184, 217, 0.1);
      }

      &[data-is-correct="true"] {
        background: rgba($green, 0.2);
        box-shadow: inset rgba($green, 0.6) 0 0 20px 8px;
      }

      &[data-is-wrong="true"] {
        background: rgba($red, 0.2);
        box-shadow: inset rgba($red, 0.4) 0 0 20px 8px;
      }

      .single-track-progress-bar {
        left: 0;
        top: 0;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          width: 0%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 101, 255, 0.3);
          transition: width 0.1s linear, opacity 200ms ease;
        }

        @for $i from 0 through 100 {
          &[data-progress="#{$i}"] {
            &::before {
              width: #{$i + "%"};
            }
          }
        }
      }

      .note-icon {
        margin-top: 5px;
        width: 32px;
        pointer-events: none;

        @media screen and (min-height: 789px) {
          width: 48px;
        }

        @media screen and (min-height: 821px) {
          margin-top: 15px;
        }
      }
    }

    .remove-button {
      color: #fff;
      padding: 5px 10px;
      transform: scale(1);
      transition: all 200ms;

      &:hover {
        transform: scale(1.4);
      }
    }

    .pitch-filled {
      border: 1px solid #33e9e9;
      background: rgba(51, 233, 198, 0.05);
    }

    &.ended {
      .pitch {
        .single-track-progress-bar {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  .correct-notes {
    background-color: #00b8d9;
    width: 100%;
    border-radius: 12px;
  }

  .wrong-notes {
    background-color: #e85ac0;
    width: 100%;
    border-radius: 12px;
  }
}

.App {
  &[data-is-touch-device="true"] {
    .virtuoso {
      .play-button-area,
      .play-song-button {
        .play-button {
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1.4);
          }
        }
      }
    }
  }
}
