.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transform: translateY(110vh);
  transition: transform 400ms ease 0ms, z-index 0ms ease 410ms;

  .modal-content {
    width: 100%;
    max-width: 600px;
    max-height: 70vh;
    background-color: $dark-blue;
    color: #fff;
    border-radius: 10px;
    padding: 10px 25px;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid 1px transparent;
    border-radius: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: 10px;
      background: linear-gradient(to top, #6554c0, #e85ac0);
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      padding: 5px 1px;
      .modal-title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }
      .modal-close {
        cursor: pointer;
        font-size: 24px;
        color: #fff;
      }
    }
    .modal-body {
      margin-top: 20px;
      .modal-text {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  &.show {
    transform: translateY(0);
    z-index: 99;
    transition: transform 400ms ease 10ms, z-index 0ms ease 0ms;
  }

  &.game-end-modal {
    &.show {
      z-index: 999 !important;
    }
  }
}

.game-layout {
  .modal {
    .modal-content {
      margin-top: -100px;
    }
  }
}
