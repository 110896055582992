.record-select {
  .records {
    width: 90%;
    max-height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    column-gap: 30px;
    row-gap: 80px;
    padding-top: 60px;
    height: max-content;

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    &[data-has-selected="true"] {
      .record {
        pointer-events: none;
      }
    }

    .selected-record {
      animation-duration: 0.5s;
      animation-name: animate-fade;
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
    }

    .record {
      animation-name: animate-pop;
      animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
      animation-duration: 0.5s;

      &:hover {
        .record-part {
          transform: translate(-50%, -64%);
        }
      }

      .record-part {
        z-index: 1;
        position: relative;
        top: 0;
        left: 50%;
        transform: translate(-50%, -43%);
        transition: all 0.5s;
        width: 70px;
      }

      .record-cover {
        z-index: 2;
        position: relative;

        .selected-user {
          bottom: 10px;

          .UserInfo {
            background: rgba(9, 4, 40, 0.75);
            border: 1px solid #5c5870;
            padding: 12px 20px;
          }
        }
      }

      &[data-is-selected="true"] {
        pointer-events: none;
        .record-part {
          transform: translate(-50%, 0);
          top: 15px;
          width: 95px;
          pointer-events: none;
        }
      }

      &[data-is-disabled="true"] {
        pointer-events: none;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 80%;
      background-color: rgba(100, 84, 192, 0.336);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6554c0;
      border-radius: 10px;
      height: 10px !important;
    }
  }
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
