.avatar-selecter-holder {
  .select-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    background: linear-gradient(
        0deg,
        rgba(9, 4, 40, 0.25),
        rgba(9, 4, 40, 0.25)
      ),
      linear-gradient(180deg, #443882 0%, #a8438b 100%);

    .select-avatar-image {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }

    @include media-breakpoint-up(md) {
      width: 100px;
      height: 100px;
    }
  }

  .select-avatar-button {
    padding: 8px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .edit-icon {
    position: relative;
    top: -25px;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 44%;
    outline: none;
    cursor: pointer;
    background: #090428;
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #090428;
    transition: border 200ms;
    padding: 0;

    &:nth-child(3n + 1) {
      background: #37035e;
    }
    &:nth-child(3n + 2) {
      background: #5b0979;
    }
    &:nth-child(3n + 3) {
      background: #4a0385;
    }

    img {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }

    @include media-breakpoint-up(md) {
      width: 100px;
      height: 100px;
    }
  }

  .select-avatar-content {
    max-height: 56vh;
  }

  .avatars-holder {
    height: max-content;
    max-height: calc(100% - 74px);
  }

  .selected-avatar {
    border-color: #fff;
  }
  .modal {
    .modal-content {
      max-width: 710px;
    }
  }

  &.modal-fix {
    .modal-content {
      margin-top: 0 !important;
    }
  }
}
