.tutorial-page {
  top: 0;
  left: 0;
  z-index: 999999;
}

.tutorial-modal {
  .modal-content {
    border-radius: 30px;
  }
}

.Tutorial {
  .card {
    height: max-content;
    max-height: calc(80vh - 10px);
  }

  .icons-area {
    grid-template-columns: repeat(3, 80px);
    justify-content: center;
    column-gap: 10%;

    .icon {
      width: 80px;
      height: 80px;
      padding: 10px;
      opacity: 0.6;
      transition: all 200ms;

      img {
        object-fit: cover;
      }

      .role-name {
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
        top: -5px;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 8px;
        opacity: 0;
        transition: all 200ms ease 0ms;
      }

      &:hover {
        opacity: 0.8;

        .role-name {
          opacity: 1;
          transition-delay: 600ms;
        }
      }

      &[data-is-selected="true"] {
        opacity: 1;
      }

      &[data-is-hidden="true"] {
        display: none;
      }
    }
  }

  .tutorial-area-holder {
    border-radius: 48px;
    margin-top: 15px;
    max-height: 100%;
    overflow: auto;

    $transition: all 400ms ease-in-out;
    .up-arrow {
      position: absolute;
      top: -12px;
      left: 0;
      transform: translateX(50%);
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba($color: #ffffff, $alpha: 1);
      transition: $transition;
    }

    .tutorial-pages {
      border-radius: 48px;
      .pages {
        width: calc(100% * 3);
        border-radius: 48px;
        transition: $transition;

        @for $i from 0 through 2 {
          &[data-selected-index="#{$i}"] {
            transform: translateX(calc(100% / 3 * -#{$i}));
          }
        }

        .game-layout {
          flex-grow: 0;
          max-width: calc(100% / 3);
          .virtuoso,
          .gamer {
            background-size: cover !important;
            background-position: center !important;
            border-radius: 48px;
          }

          .virtuoso {
            .notes-holder {
              .note-holder {
                height: 200px;
              }
            }

            .pitch-area {
              .pitch {
                height: 90px;
              }
            }
          }

          .gamer {
            .notes-holder {
              padding: 0 35px;

              .note-holder {
                height: 455px;
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    height: 75px;
  }
}

.introjs-tooltip {
  &.tutorial-tooltip {
    background: rgba(9, 4, 40, 0.7);
    box-shadow: 0px 10px 15px -3px rgba(1, 102, 255, 0.2),
      0px 4px 6px -2px rgba(6, 25, 56, 0.05);
    backdrop-filter: blur(20px);
    border: solid 0.5px $purple;
    border-radius: 10px;

    & > * {
      color: #fff;
    }

    .introjs-skipbutton {
      color: #fff;
    }

    .introjs-arrow {
      &.top {
        border-bottom-color: $purple;
      }
    }

    .introjs-tooltipbuttons {
      .introjs-button {
        background: linear-gradient(180deg, #6554c0 0%, #e85ac0 100%);
        border: none;
        width: auto;
        height: auto;
        border-radius: 8px;
        padding: 6px 10px;
        color: #fff;
        text-shadow: none;
        box-shadow: none !important;

        &.introjs-disabled {
          background: #252040;
          opacity: 0.7;
          color: darken($color: #ffffff, $amount: 15%);
        }
      }
    }
  }
}

.tutorial-type-icon {
  padding: 10px;

  img {
    object-fit: cover;
  }

  .role-name {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    opacity: 0;
    transition: all 200ms ease 0ms;
  }

  &.highlighted {
    opacity: 1;
    z-index: 9999999;
  }
}
