.game-over-page {
  .songs {
    height: auto !important;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      height: 35vh !important;
      padding: 20px;
    }

    .track-status {
      width: 64px;
      height: 64px;

      img {
        object-fit: cover;
      }
    }
  }

  .FeedbackModal {
    .stars {
      &[data-disabled="true"] {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }

  .demo-over-text {
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: calc(1.375rem + 1.5vw);
    }
  }
}
