.gamer {
  background: url("../../assets/img/gamer-bg.png");
  background-size: cover;
  background-position: center 10px;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--footer-height) * -1);

  @include media-breakpoint-up(lg) {
    background-size: calc(100vw + 30px);
  }

  .card {
    padding: 55px 0;
  }
}
