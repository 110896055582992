.record-box-select-card-holder {
  .record-break-video {
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transition: opacity 400ms ease 0ms, z-index 0ms ease 410ms;

    @include media-breakpoint-up(md) {
      border-radius: 70px;
    }

    &[data-is-in="true"] {
      opacity: 1;
      z-index: 9;
      transition: opacity 400ms ease 10ms, z-index 0ms ease 0ms;
    }

    @include media-breakpoint-up(md) {
      top: 24px;
      left: 24px;
      max-width: calc(100% - 48px);
      max-height: calc(100% - 48px);
    }
  }
}
