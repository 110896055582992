.guest {
  background: url("../../assets/img/gamer-bg.png");
  background-size: cover;
  background-position: center;
  margin-bottom: -#{$footerHeight};

  @include media-breakpoint-up(md) {
    margin-bottom: -#{$mdFooterHeight};
  }

  .note {
    position: relative;
    height: 55vh;
    width: 10vw;
    margin-right: -30px;
    margin-left: -30px;
    background: url("../../assets/notes/guest-note-bg.svg");
    background-size: 100% 100%;
    &-disabled {
      opacity: 0.5;
    }
    cursor: pointer;
  }

  .note-playing-icon {
    position: absolute;
    top: 78%;
    right: 43%;
  }
}
