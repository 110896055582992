.textarea {
  padding: 12px 10px;
  background: #171234;
  border: 1px solid #322e4c;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  resize: none;

  &:focus {
    outline: none;
    background: #171234;
    color: #fff;
    border: 1px solid #6554c0;
  }

  &:disabled {
    background: #171234 !important;
    border: 1px solid #322e4c !important;
  }
}
