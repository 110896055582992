.speech-check {
  background: #090428;
  border: 2px solid #252040;
  max-width: 460px;
  border-radius: 20px;

  &[data-is-disabled="true"] {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      background: rgba($color: #000000, $alpha: 0.7);
      border-radius: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    border-radius: 50px;
  }
}

.volume-box {
  width: 20px;
  height: 30px;
  background: #4c987d;
  border-radius: 4px;
}
.volume-box-empty {
  background: #322e4c;
}
