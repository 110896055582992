p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 80%;
    background-color: rgba(100, 84, 192, 0.336);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6554c0;
    border-radius: 10px;
    height: 10px !important;
  }
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.main,
.second-main {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.main {
  background-image: url("./img/bg-default.jpg"),
    url("./img/bg-default-low-res.jpg");
  background-color: $primary;
  .row {
    width: inherit;
  }
}

.second-main {
  background-image: url("./img/bg-second-default.jpg"),
    url("./img/bg-second-default-low-res.jpg");
  margin-bottom: -#{$footerHeight};

  @include media-breakpoint-up(md) {
    margin-bottom: -#{$mdFooterHeight};
  }

  .row {
    width: inherit;
  }
}

.rotate {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.play-button {
  width: 70px;
  height: 70px;
  background: linear-gradient(180deg, #6554c0 0%, #e85ac0 100%);
  border-radius: 50%;
  border: none;

  &-disabled,
  &:disabled {
    background: #252040;
    opacity: 0.7;
    color: darken($color: #ffffff, $amount: 15%);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-up(lg) {
    &::before {
      display: none;
    }
  }
}

.play-button-area {
  height: 80px;
  width: 100%;
  margin-top: auto;
  background: rgba(9, 4, 40, 0.5);
  box-shadow: 0px 1px 3px rgba(9, 4, 40, 0.1), 0px 1px 2px rgba(9, 4, 40, 0.05);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  @include media-breakpoint-up(md) {
    height: 100px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
  }
}

.gradient-border {
  position: relative;
  border-radius: 20px;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(180deg, $purple, $pink);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  background: #090428;
  transition: opacity 400ms ease 0ms, z-index 0ms ease 410ms;
  &-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &[data-is-in="true"] {
    opacity: 1;
    z-index: 99999;
    transition: opacity 400ms ease 10ms, z-index 0ms ease 0ms;
  }

  .video-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($color: #000000, $alpha: 0.4);
    width: 64px;
    height: 64px;
    padding: 15px;
    z-index: 99;
  }
}

.video-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba($color: #000000, $alpha: 0.4);
  width: 64px;
  height: 64px;
  padding: 15px;
  z-index: 99;
}

.notes-holder {
  width: 85%;
  padding: 0 25px;

  @include media-breakpoint-up(md) {
    height: 100%;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }

  .note-holder {
    width: 150px;
    height: 530px;
    margin: 0 6px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    max-height: 100%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 12px;
    }

    &[data-is-selected="true"] {
      opacity: 0.6;

      & > .note-border {
        filter: grayscale(0.8);
      }
    }

    .play-note-button {
      top: 15%;
      z-index: 999;
      height: 26px;

      &[data-disabled="true"] {
        pointer-events: none;
        opacity: 0.5;
      }

      .button-tooltip {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, calc(100% - 10px));
        font-size: 12px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        border: 1px solid #fff;
        padding: 8px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.1);
        box-shadow: 0 0 8px 4px rgba($color: #000000, $alpha: 0.1);
        opacity: 0;
        transition: all 300ms;

        &:before {
          $triangle: 7px;
          content: "";
          position: absolute;
          top: #{"-#{$triangle}"};
          left: 50%;
          transform: translate(-50%, 0);
          border-left: $triangle solid transparent;
          border-right: $triangle solid transparent;
          border-bottom: $triangle solid #fff;
        }
      }
    }

    .note-border {
      border-radius: 20px;
      height: 100%;
      width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);

      &--1 {
        border: 3px solid #ffffff;
        filter: blur(0.5px);
        z-index: 7;
      }

      &--2 {
        border: 6px solid #33e9e9;
        filter: blur(0.8px);
        z-index: 6;
      }

      &--3 {
        border: 10px solid rgba(51, 233, 198, 0.2);
        box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.5);
        filter: drop-shadow(0px 16px 20px #009999)
          drop-shadow(0px 10px 8px rgba(0, 77, 70, 0.7));
        mix-blend-mode: hard-light;
        z-index: 5;
      }

      &--4 {
        mix-blend-mode: hard-light;
        border: 13px solid #00a8a8;
        filter: blur(8px);
        z-index: 4;
      }

      &--5 {
        mix-blend-mode: hard-light;
        border: 10px solid #00a8a8;
        filter: blur(4px);
        z-index: 3;
      }

      &--6 {
        mix-blend-mode: hard-light;
        border: 10px solid #00a8a8;
        filter: blur(4px);
        z-index: 2;
      }

      &--7 {
        background: rgba(51, 233, 198, 0.1);
        mix-blend-mode: hard-light;
        filter: blur(4px);
        z-index: 1;
      }
    }
    .note {
      width: 75%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      pointer-events: none;

      @include media-breakpoint-up(md) {
        width: 60px;
      }
    }

    .note-playing-icon {
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
    }

    .note-selected-order {
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 28px;
      color: #fff;
    }

    &.demo-notes {
      .note-playing-icon {
        top: 0;
        bottom: 0;
        width: 26px;
      }
    }

    &:hover {
      .play-note-button {
        .button-tooltip {
          opacity: 1;
          transform: translate(-50%, calc(100% + 15px));
        }
      }
    }
  }
}

button {
  position: relative;

  .tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px));
    padding: 5px 10px;
    z-index: 999;
    background: #fff;
    color: #000;
    width: max-content;
    border-radius: 8px;
    visibility: hidden;
    transition: all 200ms;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 5px solid transparent;
      border-top-color: #fff;
    }

    &--bottom {
      top: auto;
      bottom: 0;
      transform: translate(-50%, calc(100% + 10px));

      &::before {
        top: auto;
        bottom: 100%;
        border-top-color: transparent;
        border-bottom-color: #fff;
      }
    }

    &--left {
        top: 50%;
        left: 0;
        transform: translate(calc(-100% - 10px), -50%);

        &::before {
            top: 50%;
            left: 100%;
            transform: translate(0, -50%);
            border-left-color: #fff;
        }
    }
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.text-center {
  & > * {
    text-align: center;
  }
}

.connection-error {
  max-width: 260px;
  top: 15px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 20px));
  border-radius: 8px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba($color: #127800, $alpha: 0.85);
  transition: transform 200ms ease 2s;
  box-shadow: 0 0 10px 2px rgba($color: #000000, $alpha: 0.2);
  z-index: 999999;

  &[data-is-poor="true"] {
    background-color: rgba($color: #780000, $alpha: 0.85);
  }

  &[data-is-in="true"] {
    transform: translate(-50%, 0);
    transition: transform 200ms ease 0s;
  }
}

.end-video-cover,
.intro-video-cover {
  top: 0;
  left: 0;
  z-index: 999999;
  opacity: 1;
  object-fit: cover;
  transition: opacity 400ms ease 10ms, z-index 0ms ease 0ms;

  &.intro-video-cover {
    z-index: 9999;
  }

  &.over {
    opacity: 0;
    z-index: -1;
    transition: opacity 400ms ease 0ms, z-index 0ms ease 410ms;
  }
}

.GuestList {
  top: calc(9vh + 15px);
  left: 15px;
  border-radius: 8px;
  padding: 10px;
  min-width: 130px;
  background: rgba($color: #090428, $alpha: 0.7);
  box-shadow: 0px 10px 15px -3px rgba($color: #0166ff, $alpha: 0.2),
    0px 4px 6px -2px rgba($color: #061938, $alpha: 0.05);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border: solid 0.5px #8777d9;
  z-index: 1;

  .list-title {
    font-size: 16px;
    border-bottom: 1px solid #ccc;
  }

  .list {
    z-index: 2;

    .list-item {
      margin-top: 15px;

      .avatar {
        width: 24px;
        margin-right: 5px;

        img {
          object-fit: cover;
          width: calc(100% - 6px);
          height: calc(100% - 6px);
        }
      }

      .name {
        font-size: 14px;
      }
    }
  }
}

.agreement-page {
  h1,
  h2 {
    text-align: center;
  }

  h4 {
    font-size: 1.25rem;
    margin-top: 1.5rem;
  }

  p {
    margin-bottom: 0.75rem;
  }
}

.skip-song-button {
  background: linear-gradient(180deg, #6554c0 0%, #e85ac0 100%);
  border-radius: 8px;
  border: none;
  top: calc(9vh + 15px);
  right: 25px;
  color: #fff;
  font-size: 0.925rem;
  padding: 5px 15px;
  z-index: 9;

  &-disabled,
  &:disabled {
    background: #252040;
    opacity: 0.7;
    color: darken($color: #ffffff, $amount: 15%);
  }

  .tooltip {

    background: #252040;
    color: #fff;
    max-width: 175px;

    &::before {
      border-left-color: #252040;
    }
  }
}

.version-number {
  top: 2px;
  right: 5px;
  background-color: #000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 11px;
  opacity: 0.3;
  pointer-events: none;
}

.description-mio {
  height: 100%;
  max-height: 20vh;
}

.footer-placeholder {
  height: 131px;
  pointer-events: none;
  visibility: hidden;

  @include media-breakpoint-up(md) {
    height: 115px;
  }
}

.introjs-skipbutton {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  z-index: -1 !important;
}