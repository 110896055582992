.accordion {
  width: 100%;
  background: #171234;
  border: 1px solid #403c58;
  border-radius: 4px;

  .accordion-icon {
    transition: transform 200ms;
  }

  .summary {
    color: #97a0af;
    &::marker {
      font-size: 0;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  .details {
    border-top: 1px solid #403c58;
  }

  &[open] {
    .summary {
      .accordion-icon {
        transform: rotate(0deg);
      }
    }
  }
}
