.faq {
  position: absolute;
  right: 5px;
  bottom: 110px;
  z-index: 9;

  @include media-breakpoint-up(md) {
    position: relative;
    right: auto;
    bottom: auto;
  }

  .speech-bubble {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 20px 20px 20px 1px;
    background: rgba(9, 4, 40, 0.5);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 20px 20px 20px 1px;
      padding: 1px;
      background: linear-gradient(180deg, $purple, $pink);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}

.pre-game-layout {
  &:not(.game-over-page) {
    .faq {
      bottom: 52px;

      @include media-breakpoint-up(md) {
        bottom: auto;
      }
    }
  }
}
