.header {
  width: 100vw;
  height: 9vh;
  background: rgba(9, 4, 40, 0.5);
  box-shadow: 0px 4px 6px -2px rgba(6, 25, 56, 0.05);
  backdrop-filter: blur(20px);
  border-bottom: 1px solid rgb(180, 24, 136);

  .score,
  .difficulty {
    background: #252040;
    border: 1px solid #322e4c;
    padding: 10px 16px;
    border-radius: 12px;
  }

  .extra-title {
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    max-width: 220px;

    @include media-breakpoint-up(lg) {
      max-width: max-content;
    }

    &.flashing {
      animation: flash 0.5s 2;
    }
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
