.musing-button {
  padding: 16px 32px;
  color: #fff;
  font-weight: bold;
  background: rgba(9, 4, 40, 0.5);
  border-radius: 50px;

  &:hover {
    color: #fff;
    background: rgba(9, 4, 40, 0.8);
  }

  &.button-border {
    position: relative;
    border-radius: 50px;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1px;
      background: linear-gradient(180deg, $purple, $pink);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  &.btn-width-full {
    width: -webkit-fill-available;
  }

  &:disabled {
    background: #4e4a64;
    color: #777488;
    cursor: not-allowed;
    border-color: transparent !important;

    &:before {
      opacity: 0;
    }
  }
}

.App[data-is-touch-device="true"] .musing-button,
.App[data-is-touch-device="true"] .btn {
  &:hover {
    background: rgba(9, 4, 40, 0.5);
    color: #fff;
    border: none;
  }
}
