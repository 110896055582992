.UserInfo {
  border: 1px solid #322e4c;
  border-radius: 16px;
  height: 48px;

  .border {
    border-radius: 16px;
    opacity: 0;
    transition: all 300ms ease-in-out;

    &--1 {
      border: 3px solid #ffffff;
      filter: blur(0.5px);
      z-index: 7;
    }

    &--2 {
      border: 6px solid #33e9e9;
      filter: blur(0.8px);
      z-index: 6;
    }

    &--3 {
      border: 10px solid rgba(51, 233, 198, 0.2);
      box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.5);
      filter: drop-shadow(0px 16px 20px #009999) drop-shadow(0px 10px 8px rgba(0, 77, 70, 0.7));
      mix-blend-mode: hard-light;
      z-index: 5;
    }

    &--4 {
      mix-blend-mode: hard-light;
      border: 13px solid #00a8a8;
      filter: blur(8px);
      z-index: 4;
    }

    &--5 {
      mix-blend-mode: hard-light;
      border: 10px solid #00a8a8;
      filter: blur(4px);
      z-index: 3;
    }

    &--6 {
      mix-blend-mode: hard-light;
      border: 10px solid #00a8a8;
      filter: blur(4px);
      z-index: 2;
    }

    &--7 {
      background: rgba(51, 233, 198, 0.1);
      mix-blend-mode: hard-light;
      filter: blur(4px);
      z-index: 1;
    }
  }

  &[data-is-playing="true"] {
    box-shadow: 0px 10px 15px -3px rgba($color: #0166ff, $alpha: 0.2),
    0px 4px 6px -2px rgba($color: #061938, $alpha: 0.05);
    border: solid 1px #8777d9;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

  .border{
    opacity: 1;
  }

  }

  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: #252040;

    img {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
    }
  }

  .user-name {
    font-size: 16px;
  }

  .error-icon {
    margin-left: 12px;
    width: 22px;
    height: 22px;
    object-fit: cover;
  }

  .waiting-icon {
    margin-left: 8px;
    width: 12px;
    object-fit: cover;
  }

  .speaker-icon {
    margin-left: 8px;
    object-fit: cover;
    width: 22px;
    margin-top: -2px;
  }

  .info-area {
    position: relative;
    z-index: 999;

    &__tooltip {
      background: #322e4c;
      border-radius: 8px;
      padding: 8px 12px;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      max-width: 200px;
      text-align: center;
      width: max-content;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -45px);
      z-index: -1;
      opacity: 0;
      transition: opacity 200ms ease 0ms, transform 200ms ease 0ms,
      z-index 0ms ease 210ms;
    }

    .error-icon,
    .waiting-icon {
      cursor: help;
      z-index: 9;

      &:hover {
        & + .info-area__tooltip {
          transform: translate(-50%, -32px);
          opacity: 1;
          z-index: 3;
          transition: opacity 200ms ease 10ms, transform 200ms ease 10ms,
          z-index 0ms ease 0ms;
        }
      }
    }
  }
}
