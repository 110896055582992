.musing-select {
  .form-select {
    background: #171234;
    border: 1px solid #322e4c;
    border-radius: 10px;
    color: #cac9d1;
    font-weight: 300;
    padding: 12px 30px;
    line-height: 120%;
  }

  &[data-is-disabled="true"] {
    & > * {
      pointer-events: none;
    }
  }
}
