.footer {
  z-index: 9999;
  width: 100%;
  max-width: 100vw;

  .leave-room-button {
    background: rgba(9, 4, 40, 0.7);
    box-shadow: 0px 0px 3px $dark-blue, inset 0px 0px 1px 0.5px $pink;
    backdrop-filter: blur(10px);

    .tooltip {
      z-index: -1;
      right: 50%;
      transform: translate(-50%, calc(-100% - 20px));
      background: rgba(9, 4, 40, 0.9);
      box-shadow: 0px 0px 3px $dark-blue, inset 0px 0px 1px 0.5px $pink;
      backdrop-filter: blur(10px);
      border: 1px solid $purple;
      border-radius: 14px;
      padding: 18px;
      color: $white;
      font-size: 16px;
      width: max-content;
      max-width: 160px;
      opacity: 0;
      transition: all 300ms;
      pointer-events: none;

      &:after {
        content: "";
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        transform: translateX(calc(-100% + 10px));
        border-width: 10px;
        border-style: solid;
        border-color: $purple transparent transparent transparent;
      }
    }

    &:hover {
      color: #fff;
      background: rgba(9, 4, 40, 0.5);

      .tooltip {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .mute-button {
    z-index: 99;

    &[data-hide="true"] {
      opacity: 0;
      pointer-events: none;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .show-muted-info {
      z-index: -1;
      right: 0;
      transform: translateY(calc(-100% - 20px));
      background: rgba(9, 4, 40, 0.7);
      box-shadow: 0px 0px 3px $dark-blue, inset 0px 0px 1px 0.5px $pink;
      backdrop-filter: blur(10px);
      border: 1px solid $purple;
      border-radius: 14px;
      padding: 18px;
      color: $white;
      font-size: 16px;
      width: max-content;
      max-width: 178px;
      opacity: 0;
      transition: opacity 200ms ease 0ms, z-index 0ms ease 210ms;
      pointer-events: none;

      &:after {
        content: "";
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        transform: translateX(calc(-100% + 10px));
        border-width: 10px;
        border-style: solid;
        border-color: $purple transparent transparent transparent;
      }

      &[data-show="true"] {
        opacity: 1;
        z-index: 999;
        pointer-events: all;
        transition: opacity 200ms ease 10ms, z-index 0ms ease 0ms;
      }

      .close {
        top: 2px;
        right: 8px;
      }
    }
  }

  .footer-action-buttons {
    z-index: 999;
  }

  .content {
    width: 100%;
    .countdown-content {
      min-width: 220px;
      height: 50px;
      background: linear-gradient(180deg, #6554c0 0%, #e85ac0 100%);

      box-shadow: 0px 4px 6px -2px rgba(6, 25, 56, 0.05),
        0px 10px 15px -3px rgba(1, 102, 255, 0.2);

      backdrop-filter: blur(20px);
      border-radius: 60px 60px 0px 0px;

      @include media-breakpoint-up(md) {
        width: 58%;
        border-radius: 100px 100px 0px 0px;
      }

      .label {
        font-size: 14px;
        margin-right: 15px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          margin-right: 24px;
        }
      }

      .time-left {
        font-size: 18px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
          font-size: 22px;
        }
      }
    }

    .users-content {
      box-sizing: border-box;
      width: 100%;
      max-width: 80vw;
      background: rgba(9, 4, 40, 0.8);
      box-shadow: 0px 10px 15px -3px rgba(1, 102, 255, 0.2),
        0px 4px 6px -2px rgba(6, 25, 56, 0.05);
      backdrop-filter: blur(20px);
      border-top: 1px solid #6554c0;
      border-radius: 50px 50px 0px 0px;

      @include media-breakpoint-up(lg) {
        max-height: 80px;
        border-radius: 200px 200px 0px 0px;
        padding: 12px 0px;
      }

      .users-holder {
        grid-template-columns: repeat(
          auto-fit,
          minmax(calc(100% / 6 - 10px), 1fr)
        );
        column-gap: 10px;
        margin: 0 15px;

        @include media-breakpoint-up(xl) {
          grid-template-columns: repeat(
            auto-fit,
            minmax(calc(100% / 6 - 20px), 1fr)
          );
          column-gap: 20px;
          margin: 0;
        }

        .UserInfo {
          box-shadow: 0 0 7px 14px rgba($color: #000000, $alpha: 0.1);
          .avatar {
            margin-right: 8px;
            width: 24px;
            height: 24px;
          }
          .user-name {
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }

      .game-end-button {
        font-size: 14px;
        width: calc(100% / 3 - 10px);
        padding: 15px 10px;

        @include media-breakpoint-up(md) {
          width: auto;
        }

        @include media-breakpoint-up(xl) {
          font-size: 16px;
          padding: 16px 32px;
        }

        .button-icon {
          width: 16px;
          margin-right: 0;
          margin-bottom: 3px;

          @include media-breakpoint-up(md) {
            margin-right: 5px;
          }
        }

        .button-text {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline-block;
          }
        }
      }
    }
  }
}
