.countdown-number {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px !important;
  padding: 12px 24px;
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  border-radius: 10px;
  background: rgba(9, 4, 40, 0.5);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(180deg, $purple, $pink);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.paused-fix {
  .countdown-number {
    font-size: 35px;
  }
}
