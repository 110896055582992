.card {
  height: auto;
  background: rgba(9, 4, 40, 0.7);
  box-shadow: 0px 10px 15px -3px rgba(1, 102, 255, 0.2),
    0px 4px 6px -2px rgba(6, 25, 56, 0.05);
  backdrop-filter: blur(20px);
  border: solid 0.5px $purple;
  border-radius: 30px;
  position: relative;
  padding-top: 64px;


  @include media-breakpoint-up(md) {
    border-radius: 70px;
    height: 65vh;
    padding-top: 0;
  }

  .card-page-title {
    font-size: 20px;

    @include media-breakpoint-up(md) {
      font-size: 26px;
    }

    @media screen and (min-height: 860px) and (min-width: 768px) {
      font-size: 36px;
    }
  }

  .card-page-description {
    font-size: 16px;

    @media screen and (min-height: 860px) {
      font-size: 20px;
    }
  }

  .logo {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    height: 25vh;
    max-height: 200px;
    max-width: 75vw;
  }

  &.record-box-select-card-holder {
    height: 65vh;

    .video-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba($color: #000000, $alpha: 0.4);
      width: 64px;
      height: 64px;
      padding: 15px;
      z-index: 99;
    }
  }
}

.pre-game-layout {
  .card {
    padding-top: 64px;

    @include media-breakpoint-up(md) {
      padding-top: calc(25vh / 2);
    }

    @media screen and (min-height: 860px) and (min-width: 768px) {
      padding-top: calc(25vh / 2 - 50px);
    }
  }

  &.game-over-page {
    &:not(.demo) {
      padding-top: 8vh;

      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }
  }

  &.welcome {
    .card {
      padding-top: 0;
      height: 75vh;
      transform: translateY(3vh);
    }
  }

  &.demo {
    padding-top: 15vh;

    &.game-over-page {
      @include media-breakpoint-up(md) {
        padding-top: 10vh;
      }
    }

    .card {
      padding-top: 64px;

      @include media-breakpoint-up(md) {
        padding-top: calc(25vh / 2);
      }

      @media screen and (min-height: 860px) and (min-width: 768px) {
        padding-top: calc(25vh / 2 - 50px);
      }
    }

    &.game-over-page {
      .card {
        padding: 64px 20px 0;

        @include media-breakpoint-up(md) {
          padding-top: 64px;
        }
      }
    }

    &-sound-check-fix {
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }

      @media screen and (min-height: 860px) and (min-width: 768px) {
        padding-top: 0;
      }
    }
  }

  &.close-tab-info {
    .card {
      padding: 5rem 0 !important;
    }
  }
}

